/* Survey.css */
.survey-container {
    display: flex;
    flex-direction: column;
    align-items: center;

  }
  
  .survey-header {
    text-align: center;
    font-size: 28px;
  }
  
  .survey-form {
    width: 1000px;
    max-width: 600px; /* Ajusta esto según prefieras */
    border: 1px solid transparent;
    border-radius: 15px;
    background-image: linear-gradient(white, white), radial-gradient(circle at top left, #ff0080, #7700ff, #0084ff);
    background-origin: border-box;
    background-clip: content-box, border-box;
    padding: 1rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
  }

  .survey-form-pin {
    width: 1000px;
    max-width: 600px;
    border: 1px solid transparent;
    border-radius: 15px;
    background-color: white;
    padding: 0.25rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    height: fit-content;
    padding: 20px;
    margin-top: 0.75rem;
}

.survey-form h2{
    margin-left: 10rem;
}
  .survey-question {
    margin-bottom: 1rem;
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.survey-header h2{
  margin: 0;
}
.survey-header h3{
  font-size: 20px;
  margin-bottom: 0;
}

.survey-msg-container{
  border-radius: 16px;
  width: 750px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: white;
  height: 250px;
  margin-top: 10rem;
}



.survey-completion-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 70%;
  margin-bottom: 2rem;
  margin-top: 1rem;
}

.review-questions {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: white;
  width: 650px;
  padding: 1rem;
  border-radius: 16px;
  margin-bottom: 2rem;

}

  .submit-button {
    display: block;
    width: 50%;
    padding: 10px;
    background-color: #0084ff; /* Azul */
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 2rem;
    margin-left: 8rem;
    transition: background-color 0.3s ease;
  }
  
  .submit-button:hover {
    background-color: #ff0080; /* Rosa */
  }
  
/* 
  Success */

  .success-container {
    font-size: 24px;
    padding: 20px;
    background-color: #4CAF50;
    color: white;
    margin-bottom: 15px;
    position: relative;
    top: 180px;
    width: 350px;
    font-weight: 500;
}
  
  .success-container p {
    margin: 0;
  }
  
  .success-container button {
    color: white;
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    font-size: 20px;
    width: 100%;
    text-align: right;
}
  

  .answer-buttns{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 1rem;
  }