/* Pricing1-PricingTable */

.pricing-table{
    width: 100%;
    margin-top: 5rem;
}
.pricing-table table {
    width: 100%;
    text-align: center;
  }
  
  .pricing-table th,
  .pricing-table td {
    padding: 10px;
    
  }
  .pricing-table tbody th {
    text-align: left; /* Alinea el texto a la izquierda */
    padding-left: 15px; 
    background-color: transparent;
    font-weight: 400;
  }
  .pricing-table td{
    text-align: center;
  }
  .pricing-table thead th {
    text-align: center;
    background: transparent;
    font-size: 24px;
}
  .pricing-table .fa-circle-check {
  color:  #3164f4;
  }
  
  .plan {
    padding: 30px;
    border-radius: 8px;
    width: 350px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.plan-name {
    font-size: 36px;
    font-weight: 600;
    margin-bottom: 20px;
    text-align: center;
    width: 350px;
}
  .features-list {
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}
  
.features-list li {
    margin-bottom: 10px;
    text-align: left;
    display: flex;
    justify-content: flex-start;
    margin: 10px;
    font-size: 20px;
}
  
  .features-list svg {
    height: 1.5rem;
    color: rgb(49, 100, 244);
    width: 1.5rem;
    margin-right: 1rem;
}



/* Pricing2-FaQ */

.faq {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 980px;
    margin-top: 3rem;
}

.faq img{
    width: 400px;
}

.faq h2{
    font-size: 48px;
    text-align: center;
    width: 500px;
    margin-top: -1rem;
}
.questions{
    display: flex;
    flex-direction: column;
    height: 300px;
    justify-content: space-around;
}

.question{
    width: 650px;
}

.question-box{
    display: flex;
    justify-content: flex-end;
    border-bottom: 2px solid lightgray;
}

.question-title{
    width: 100%;
    font-weight: 500;
    font-size: 20px;
    padding: 0.3rem;
}


/* pricing0 */

.pricing-container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    text-align: center;
  }

.pricing-header{
    display: flex;
    flex-direction: column;
    align-items: center;
}
  .pricing-header h1 {
    margin-bottom: 0;
  }
  
  .pricing-header p {
    margin-bottom: 2em;
    width: 300px;
  }
  
  .pricing-cards-container {
    display: flex;
    justify-content: space-around;
    margin-bottom: 2em;
  }
  
  .pricing-card {
    border: 1px solid #ccc;
    padding: 2em;
    border-radius: 10px;
    width: 23%;
    background-color: white;
}
  .pricing-card basic{
    height: 300px;
  }
  .pricing-card h3 {
    margin-bottom: 0.5em;
  }
  
  .price {
    font-size: 1.5em;
    margin-bottom: 0.5em;
  }
  
  .price span {
    font-size: 0.8em;
  }
  
  .pricing-card button {
    border: none;
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 1em;
  }
  
  .pricing-card button:hover {
    background-color: #0056b3;
  }
  
  .most-popular {
    position: relative;
  }
  
  .most-popular-tag {
    position: absolute;
    top: -10px;
    right: -10px;
    background-color: #ffcc00;
    padding: 5px;
    border-radius: 5px;
    font-size: 0.8em;
  }
  
  /* Agrega más estilos según necesites */
  