.home-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.about-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 980px;
    display: flex;
}

.about-section,
.about-section2,
.about-section1 {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 24px;
}

.about-section1 {
    width: 600px;
}

.about-section2 {
    width: 980px;
    margin-top: 2rem;
    border-bottom: 3px solid lightgray;
}

.about-section1 h2,
.about-section2 h2 {
    font-size: 52px;
    margin: 0;
}

.about-section2 p {
    padding: 30px;
}


/* AboutUs_1 */

.efficiency-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 3rem 0rem;
    width: 100%;
}

.text-area {
    text-align: left;
    width: 350px;
    font-size: 28px;
    display: flex;
    justify-content: center;
}

.text-area h1 {
    width: 400px;
}

.graphic-area {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 350px;
}

.graphic-area img {
    width: 26rem;
}


/* About_Us-2 */

.team-container {
    text-align: center;
    padding: 20px;
    width: 1000px;
    margin-top: -8rem;
}

.team-container h2 {
    margin-bottom: 0.5em;
    font-size: 56px;
}

.team-container p {
    margin-bottom: 3em;
    margin-top: 0;
    font-size: 24px;
}

.team-members {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    /* gap: 20px;  */
}

.team-members-img {
    border-radius: 100%;
}

.team-member {
    flex: 1;
    min-width: calc(30% - 20px);
    max-width: calc(30% - 20px);
}

.team-member h3 {
    margin-bottom: 0.25em;
}

.team-member p {
    font-size: 18px;
    color: #555;
}


/* Ajustes responsivos para pantallas más pequeñas */

@media (max-width: 768px) {
    .team-member {
        min-width: calc(50% - 20px);
        max-width: calc(50% - 20px);
    }
}

@media (max-width: 480px) {
    .team-member {
        min-width: 100%;
        max-width: 100%;
    }
}