.common-container{
    display: flex;
    position: relative;
    min-width: 1200px;
    justify-content: center;
}

.login-container {
    background-color: #ffffff;
    border-radius: 42px;
    box-shadow: 0px 1px 12px rgba(3,3,3,0.08);
  }

  .login-form{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 410px;
  }
  .login-form h2 {
    color: #030303;
    font-size: 38px;
    font-family: "Poppins";
    font-weight: 500;
    line-height: 44px;
    text-align: center;
    margin-bottom: 1rem;
    margin-top: 1rem;
  }

#email-input{
    margin-bottom: 2.5rem;
}
  .login-form input {
    top: 220px;
    left: 515px;
    width: 350px;
    height: 50px;
    padding: 0px 8px;
    border: 1px solid #030303;
    box-sizing: border-box;
    border-radius: 12px;
    background-color: #ffffff;
    color: #030303;
    /* font-size: 16px; */
    font-family: "Poppins";
    font-weight: 600;
    line-height: 21px;
    outline: none;
    margin-bottom: 1rem;
}

.password-field {
    position: relative;
  }
  
  .password-icon {
    position: absolute;
    right: 10px;
    top: 40%;
    transform: translateY(-50%);
    cursor: pointer;
  }

.forgot-password {
    display: block;
    text-align: left;
    margin-bottom: 20px;
    color: #030303;
    font-size: 14px;
    font-family: "Poppins";
    font-weight: 500;
    line-height: 21px;
    width: 80%;
}

.link-underline{
    color: #030303;
}
  .login-button {
    width: 80%;
    padding: 10px;
    border: none;
    border-radius: 12px;
    background-color: #007bff;
    color: white;
  }
  
  .divider {
    text-align: center;
    margin: 10px 0;
}
  
  .google-button,
  .microsoft-button,
  .apple-button {
    width: 80%;
    padding: 10px;
    margin-bottom: 10px;
    background-color: transparent;
    color: black;
    border: 1px solid #5a4da8;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 14px;
    font-family: "Poppins";
    font-weight: 500;
}
  .google-button .fa-google,
  .microsoft-button .fa-microsoft,
  .apple-button .fa-apple {
    margin-right: 3rem;
    width: 1.5rem;
    height: 1.5rem;
  }
  .register-link {
    display: block;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 25px;
    color: #030303;
    font-size: 14px;
    font-family: "Poppins";
    font-weight: 500;
    line-height: 21px;
    
}


.checkbox-group {
    display: flex;
    justify-content: space-between;
    width: 80%;
    margin-top: 1rem;
}
  
  .checkbox-container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 20px;
    cursor: pointer;
    font-size: 18px;
    user-select: none;
  }
  
  .checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #eee;
    border-radius: 50%;
    border: 1px solid lightgray;
  }
  
  .checkbox-container input:checked ~ .checkmark {
    background-color: #2196F3;
  }
  
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  .checkbox-container input:checked ~ .checkmark:after {
    display: block;
  }
  
  .checkbox-container .checkmark:after {
    left: 7px;
    top: 2px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
}
  
  .signup-button {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: white;
  }
  
  .login-link {
    display: block;
    text-align: center;
    margin-top: 20px;
  }

  .checkbox-container {
    display: flex;
    align-items: center;
    position: relative;
    padding-left: 35px;
    margin-bottom: 20px;
    cursor: pointer;
    font-size: 14px;
    user-select: none;
  } 

  .input-group {
    position: relative;
  }
  
  .password-icon {
    position: absolute;

    cursor: pointer;
  }

  .input-error {
    border: 2px solid red;
  }

  .error-message {
    width: 60%;
    color: red;
    /* margin-top: 10px; */
    font-size: 16px;
    /* align-items: center; */
    text-align: center;
    font-weight: 500;
}

/* PIN */

.pin-common-container{
  display: flex;
  position: relative;
  min-width: 1200px;
  justify-content: center;
  align-items: center;
  height: 400px;
}

.pin-container {
  height: fit-content;
  background-color: #e5e5e5;
  border-radius: 16px;
  border: 1px solid #262626;
  box-sizing: border-box;
  margin-left: 5rem;
  margin-top: 2rem;
  padding: 1.25rem;
}

.pin-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 350px;
  margin-top: 1.5rem;
}

.pin-form input, .name-form input, .name-form select {
  top: 220px;
  left: 515px;
  width: 225px;
  height: 50px;
  padding: 0px 8px;
  border: 0px solid #030303;
  box-sizing: border-box;
  border-radius: 12px;
  background-color: #ffffff;
  color: #030303;
  /* font-size: 16px; */
  font-family: "Poppins";
  font-weight: 600;
  line-height: 21px;
  outline: none;
  margin-bottom: 1rem;
  text-align: center;
}

.error-message.visible {
    visibility: visible;
    text-align: center;
    width: 100%;
}

/* Name */
.name-container {
  height: 220px;
  background-color: #e5e5e5;
  border-radius: 16px;
  border: 1px solid #262626;
  box-sizing: border-box;
  margin-left: 5rem;
  margin-top: 2rem;
}
.name-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 350px;
  margin-top: 2rem;
  
}


/* Welcome */

.welcome-container{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.welcome-container img{
  width: 10rem;
}

.welcome-container h1{
  font-size: 44px;
  font-family: "Poppins";
  font-weight: 500;
  line-height: 44px;
  text-align: center;
  margin:0;
}

.welcome-container h2{
  font-size: 32px;
  font-family: "Poppins";
  font-weight: 500;
  line-height: 44px;
  text-align: center;
  margin-top:0 ;
  margin-bottom: 1rem;
}

.welcome-container p{
  font-size: 36px;
  font-weight: 500;
  margin:0;
}
.chevron-icon {
  color: #000; 
  font-size: 2rem;
  width: 4rem;
  height: 4rem; 
  margin: 16px 0;
  border:1px solid black;
  border-radius: 50%; 
}

.explanation-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 800px;
  background-color: white;
  height: 450px;
  border-radius: 46px;
  justify-content: space-around;
}

.explanation-container p{
  width: 600px;
}

.explanation-container img {
  width: 8rem;
  height: 8rem;
  margin-bottom: -2rem;
}

.chevron-icon2 {
  width: 2rem;
  height: 2rem;
}

