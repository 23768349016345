.home-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.content-container {
    justify-content: space-evenly;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: -webkit-fill-available;
}

.left-side {
    display: flex;
    flex-direction: column;
    width: 400px;
    margin-left: 5rem;
}

.left-side h2 {
    font-size: 4rem;
    font-weight: 600;
    font-style: normal;
    line-height: 1.2;
    margin-top: 0;
}

.Logo-Animado {
    width: 300px;
}

.Logo-Animado-2 {
    width: 400px;
}

.logo-home1 {
    width: 26rem;
    margin: 0rem 0rem 0rem 10rem;
}

.highlight {
    color: rgb(105, 87, 221);
}

.highlight-bold {
    color: rgb(105, 87, 221);
    font-weight: 700;
}

.left-side-buttons {
    display: flex;
}

.btn-free {
    display: flex;
    align-items: center;
    background-color: blue;
    color: white;
    border-radius: 10px;
    border: 2px solid blue;
    font-weight: 600;
    padding: 10px 20px;
}

.btn-demo {
    display: flex;
    align-items: center;
    border: 0.5px solid #333;
    background-color: transparent;
    padding: 5px 20px;
    font-weight: 600;
    border-radius: 10px;
    padding: 10px 20px;
    margin-left: 1rem;
    color: #555;
}

.btn-demo:active,
.btn-demo:hover,
.btn-demo:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.btn-free:active,
.btn-free:focus,
.btn-free:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.rigth-side img {
    width: 25rem;
}


/* Component-Home_1 */

.home-2 {
    display: flex;
    justify-content: center;
}

.logo-home2 {
    width: 14rem;
    position: absolute;
    margin-top: -4em;
    margin-left: -2em;
}

.resolvemos-container {
    background-color: white;
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0 25px 25px rgba(0, 0, 0, 0.2);
    width: min-content;
    border-bottom: 12px solid #dddddd;
    z-index: 2;
}

.resolvemos-container h2 {
    font-size: 44px;
    color: #333;
    text-align: left;
    margin-bottom: 2.5rem;
    margin-top: -2rem;
    font-weight: 700;
}

.issues-list {
    list-style: none;
    padding: 0;
}

.issue-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.issue-icon {
    font-size: 24px;
    margin-right: 8px;
}

.issue-text {
    color: #555;
    font-size: 16px;
}


/* Home_2 */

.solution-container {
    width: max-content;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.solution-container h2 {
    text-align: left;
    font-size: 36px;
    display: flex;
    width: 900px;
    justify-content: flex-start;
}

.solution-container h3 {
    text-align: center;
    font-weight: 300;
    width: 650px;
    margin: 0;
    font-size: 32px;
}

.benefits-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
}

.benefit-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 30px;
}

.benefit-item p {
    font-size: 20px;
    font-weight: 700;
    width: 150px;
}

.benefit-icon {
    margin-bottom: 10px;
    width: 12rem;
    height: 10rem;
}


/* Home_3 */


/* Estilos generales */

.main-component {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.homepage_1 {
    width: 900px;
    margin-bottom: 2rem;
}

.how-it-works-box {
    text-align: center;
    padding: 20px;
    border: 2px solid black;
    border-radius: 10px;
    width: 20%;
}

.how-we-do-it {
    width: 600px;
    height: 600px;
}

.how-we-do-it-container {
    position: absolute;
    z-index: -2;
}


/* Estilos para HowWeDoIt */

@media only screen and (max-width: 944px) {
    .background-logo {
        width: 950px;
    }
}

.background-logo {
    top: -400px;
    width: 450px;
    /* height: 600px; */
    overflow: hidden;
    /* display: flex; */
    /* justify-content: center; */
    align-items: center;
    position: relative;
    right: -750px;
    z-index: -1;
}

.background-logo img {
    width: 850px;
    height: auto;
}


/* Home_4 */

.grid-container {
    display: flex;
    justify-content: space-evenly;
    align-items: self-start;
    width: 980px;
    flex-direction: column;
    align-items: center;
}

.grid-row {
    display: grid;
    grid-template-columns: 1.5fr 1fr;
    justify-content: space-between;
    align-items: center;
}

.grid-box-text,
.grid-box-text2 {
    width: 400px;
}

.grid-box-text h2,
.grid-box-text2 h2 {
    font-size: 36px;
}

.grid-box-text2 P {
    width: 450px;
}

.graphic img {
    max-width: 100%;
    height: auto;
}

button {
    background-color: blue;
    color: white;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    /* margin-top: 20px; */
    transition: background-color 0.2s;
    border-radius: 10px;
    font-size: 16px;
    font-family: "Poppins";
    font-weight: 700;
}

button:hover {
    background-color: lightgray;
}


/* Login-SignUp-Common */

.container {
    display: flex;
    position: relative;
    min-width: 1200px;
}

.logo-background {
    position: absolute;
    top: -5rem;
    width: 550px;
    height: 720px;
    background-size: contain;
    pointer-events: none;
    z-index: -1;
    display: flex;
    justify-content: flex-end;
    overflow: hidden;
    left: 5rem;
}

.logo-background img {
    width: 1000px;
    height: 750px;
}


/*ESTILOS MOVIL*/

@media only screen and (max-width: 944px) {
    .background-logo {
        display: none;
    }
}